const formatDate = (dateString) => {
  if (!dateString || typeof dateString !== "string") return null;
  const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
  if (regexYYYYMMDD.test(dateString)) return dateString;

  const regexMMDDYYYY = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
  const match = dateString.match(regexMMDDYYYY);
  if (match) return `${match[3]}-${match[1]}-${match[2]}`;

  const regexYYYYDDMM = /^(\d{4})-(\d{2})-(\d{2})$/;
  const match2 = dateString.match(regexYYYYDDMM);
  if (match2) return dateString;

  return null;
};

const msAOPDeductibleExchange = {
  // 500, 1000, 2500, 5000, 10000, 20000, 25000, 50000
  500: 0.005,
  1000: 0.01,
  2500: 0.02,
  5000: 0.03,
  10000: 0.04,
  20000: 0.05,
  25000: 0.05,
  50000: 0.05,
};

const msWindHurricaneDeductibleExchange = {
  //  2, 5, 10, 15, 20, 25, 50
  2: 0.005,
  5: 0.01,
  10: 0.02,
  15: 0.03,
  20: 0.04,
  25: 0.05,
  50: 0.05,
};

const msMedicalLiabilityExchange = {
  // 0, 1000, 2000, 5000
  //$1,000 $10,000 $2,000 $5,000"
  1000: 1000,
  2000: 2000,
  5000: 5000,
  10000: 5000,
};

const nonMSHurricaneDeductibles = {
  //  2, 5, 10, 15, 20, 25, 50
  HURRA005P: 2,
  HURRA01P: 5,
  HURRA02P: 10,
  HURRA03P: 15,
  HURRA04P: 20,
  HURRA05P: 25,
};

const nonMSNonHurricaneDeductibles = {
  // 500, 1000, 2500, 5000, 10000, 20000, 25000, 50000
  DED005P: 500,
  DED01P: 1000,
  DED02P: 2500,
  DED03P: 5000,
  DED04P: 10000,
  DED05P: 20000,
};

const phoneNumberFormatter = (phoneNumber) =>
  typeof phoneNumber === "string" &&
  phoneNumber &&
  phoneNumber.replace(/[()\-\s]/g, "").trim();

const formatAddress = ({ street_number, street_name }) =>
  (typeof street_number === "string" &&
    typeof street_name === "string" &&
    `${street_number ? `${street_number} ` : ""}${street_name}`.trim()) ||
  "";

const createClaimDetail = (lossItem) => ({
  date_of_loss: formatDate(lossItem.dateOfLoss),
  total_payout: lossItem.totalPayout,
  cause_of_loss: lossItem.causeOfLoss,
  loss_description: lossItem.lossDescription || "",
});

const createAdditionalInsured = (insured) => ({
  first_name: insured.firstName || "",
  last_name: insured.lastName || "",
  phone_number: phoneNumberFormatter(insured.phoneNumber),
});

const averageCreditScore = (firstScore, secondScore) => {
  return Math.round((firstScore + secondScore) / 2);
};

export const CreateStarQuotePool = (
  policy,
  underWriting,
  discounts,
  coverages,
  location,
  confirmation,
  selectedRisk,
  uniqueID,
  roofProperties,
  lossHistoryData,
  saveMortgagee,
  primaryMailingAddress,
  currentZipData,
  vendors,
  saveDDCoverages
) => {
  const {
    fireOtherProtectionDevice,
    fireMonitoAlarm,
    fireLocalAlarm,
    theftDeadboltLocks,
    theftLocalBurglarAlarm,
    theftLocalMonitorAlarm,
    hasSprinklarSystem,
    sprinklerType,
    hasSmartHomeProtection,
    homeWaterDetection,
    tingOrPlugDevices,
    hasTheftProtection,
    hasFireProtectionDevice,
    doesQualifyForMSwindDisc,
    hasHomeInsuranceInForce,
    isApplicantLocatedNorhI10,
  } = discounts || {};
  const { additionalInsuredFlag, additionalInsured = [] } = confirmation || {};
  const primaryInsuredInfo = additionalInsured[0] || {};
  const isAnOrg = underWriting?.propertyDetails?.customerType !== "individual";
  const { propertyDetails } = underWriting || {};
  const { firstName, lastName, email, phoneNumber, customerType, dateOfBirth } =
    propertyDetails || {};
  const {
    city,
    PostalCode: zipCode,
    state,
    country,
    CountyName,
  } = location || {};
  function findTrueCondition(storeDiscountObj) {
    if (!storeDiscountObj || typeof storeDiscountObj !== "object") {
      console.error("Invalid storeDiscountObj");
      return null;
    }
    const trueCondition = Object.entries(storeDiscountObj).find(
      ([key, value]) => value === true
    );
    return trueCondition ? trueCondition[0] : null;
  }
  const trueConditionKey = findTrueCondition(discounts.storeDiscountObj);

  const stateMap = {
    fl: "E_&_S",
    ms: "admitted",
    mississippi: "admitted",
    florida: "E_&_S",
  };

  const windType = {
    fsl: "FSL",
    IRC: "2006 IRC",
    fehBronze: "FEH Bronze",
    fehSilver: "FEH Silver",
    fehGold: "FEH Gold",
  };

  const getPKIDs = (arrayOfData, city) => {
    if (
      !arrayOfData ||
      !Array.isArray(arrayOfData) ||
      !city ||
      typeof city !== "string"
    )
      return;

    return (
      arrayOfData.find(
        (data) =>
          typeof data?.s_CityName === "string" &&
          data.s_CityName.toLowerCase().includes(city.toLowerCase())
      ) ||
      arrayOfData[0] ||
      null
    );
  };

  function getValidStateCode(state, stateCode) {
    function isValidStateCode(value) {
      return typeof value === "string" && /^[A-Z]{2}$/.test(value);
    }

    if (isValidStateCode(state)) {
      return state;
    }

    return location ? (isValidStateCode(stateCode) ? stateCode : null) : null;
  }

  function determineHurricaneProtectionType(coverages, discounts) {
    const aopDeductible = coverages?.deductibles?.aop;
    const hurricaneDeductible = coverages?.deductibles?.hurricaneDeductible;
    const windTypeKey = findTrueCondition(discounts.storeDiscountObj);
    const windTypeValue = windType[windTypeKey] || "None";

    const aopDeductibleValue =
      nonMSNonHurricaneDeductibles[aopDeductible] ?? 500;
    const hurricaneDeductiblePercentage =
      nonMSHurricaneDeductibles[hurricaneDeductible] ?? 2;

    function getDeductibleProtectionLevel() {
      if (hurricaneDeductiblePercentage <= 2 && aopDeductibleValue <= 1000) {
        return "all";
      } else if (
        hurricaneDeductiblePercentage <= 5 &&
        aopDeductibleValue <= 2500
      ) {
        return "some(greater than 50 %)";
      } else if (
        hurricaneDeductiblePercentage <= 10 &&
        aopDeductibleValue <= 5000
      ) {
        return "other";
      } else {
        return "unknown";
      }
    }

    // Function to determine protection level based on wind type
    function getWindTypeProtectionLevel() {
      if (["FEH Gold", "FEH Silver", "FSL"].includes(windTypeValue)) {
        return "all";
      } else if (["FEH Bronze", "2006 IRC"].includes(windTypeValue)) {
        return "some(greater than 50 %)";
      } else if (windTypeValue === "None") {
        return "unknown";
      } else {
        return "unknown";
      }
    }

    // X the two assessments
    const deductibleProtection = getDeductibleProtectionLevel();
    const windTypeProtection = getWindTypeProtectionLevel();

    // Choose the best protection level
    const protectionLevels = [
      "all",
      "some(greater than 50 %)",
      "other",
      "unknown",
    ];
    return protectionLevels[
      Math.min(
        protectionLevels.indexOf(deductibleProtection),
        protectionLevels.indexOf(windTypeProtection)
      )
    ];
  }

  function getCurrentLine(stateCode) {
    const keys = Object.keys(stateMap);

    if (keys.includes(stateCode)) {
      return stateMap[stateCode];
    }
    return "admitted";
  }
  const stateCode = getValidStateCode(state, location?.stateCode);
  const currentLine = getCurrentLine(stateCode);

  const current_address = propertyDetails?.isThisNewPurchase
    ? {
        street_address:
          underWriting?.propertyDetails?.newPurchaseAddr?.street_address || "",
        city: underWriting?.propertyDetails?.newPurchaseAddr?.city || "",
        zipcode: underWriting?.propertyDetails?.newPurchaseAddr?.zipcode || "",
        state: underWriting?.propertyDetails?.newPurchaseAddr?.state || "",
        county: underWriting?.propertyDetails?.newPurchaseAddr?.county || "",
      }
    : {
        street_address: formatAddress({
          street_number: location.street_number,
          street_name: location.street_name,
        }),
        city,
        zipcode: zipCode,
        state: stateCode,
        county: CountyName,
      };

  const isMSState =
    stateCode &&
    typeof stateCode === "string" &&
    stateCode.trim().toLowerCase() === "ms";

  const getPolicyEffectiveDate = () => {
    if (policy?.effectiveDate) {
      return formatDate(policy.effectiveDate);
    }
    const today = new Date();
    return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  };

  function arrayToString(array) {
    if (!array || !Array.isArray(array)) {
      return "";
    }
    return array.join(",");
  }

  const createMortgageeData = (mortgageeItem) => ({
    n_PolicyAddInterest_PK: null,
    personType:
      mortgageeItem?.type?.toLowerCase() === "primary"
        ? "PRIMARY"
        : mortgageeItem?.type?.toLowerCase() === "secondary"
        ? "SECOND"
        : "THIRD",
    mortgageeType:
      mortgageeItem?.type?.toLowerCase() === "primary"
        ? "First mortgagee"
        : mortgageeItem?.type?.toLowerCase() === "secondary"
        ? "Second mortgagee"
        : "Third mortgagee",
    loanNo: mortgageeItem?.loanNumber ?? "",
    person: {
      entityType:
        typeof customerType === "string" ? customerType.toUpperCase() : "",
      firstName: "",
      middleName: "",
      lastName: mortgageeItem?.bankName ?? "",
      isMailingDiff: "",
      isTempMailing: "",
      isInsuredTenant: "",
    },
    address: {
      addressLine1: mortgageeItem?.address ?? "",
      addressLine2: "",
      county: mortgageeItem?.county ?? "",
      city: mortgageeItem?.city ?? "",
      cityId: mortgageeItem?.cityId ?? "",
      stateId: mortgageeItem?.stateId ?? "",
      countryId: mortgageeItem?.countryId ?? "",
      stateCode: mortgageeItem?.stateCode ?? "",
      country: country ?? 1,
      countyId: mortgageeItem?.countyId ?? "",
      latitude: 0,
      longitude: 0,
      zip: mortgageeItem?.zipCode ?? "",
    },
  });

  //Whole Home Water Detection & Shut Off = homeWaterDetection
  //Ting or Plug Device = tingOrPlugDevices
  //fire -> Monitored Fire Alarm = fireMonitoAlarm
  //fire -> Local Fire Alarm = fireLocalAlarm
  //fire -> Smart Home System = fireOtherProtectionDevice

  const identifyHomeProtection = ({
    tingOrPlugDevices,
    fireLocalAlarm,
    fireMonitoAlarm,
    fireOtherProtectionDevice,
  }) => {
    if (
      !tingOrPlugDevices &&
      !fireLocalAlarm &&
      !fireMonitoAlarm &&
      !fireOtherProtectionDevice
    ) {
      return "None";
    }
    if (tingOrPlugDevices) {
      if (fireLocalAlarm) {
        return "Local Fire + Smart Home Passive";
      }

      if (fireMonitoAlarm) {
        return "Monitored Fire + Smart Home Passive";
      }

      if (fireOtherProtectionDevice) {
        return "Smart Home Active + Smart Home Passive";
      }

      return "Smart Home Passive System";
    }
    if (fireLocalAlarm) {
      return "Local Fire Alarm";
    }

    if (fireMonitoAlarm) {
      return "Monitored Fire Alarm";
    }

    if (fireOtherProtectionDevice) {
      return "Smart Home Active System";
    }
  };

  const identifyTheftProtection = ({
    theftLocalMonitorAlarm,
    theftDeadboltLocks,
    theftLocalBurglarAlarm,
  }) => {
    if (
      !theftLocalMonitorAlarm &&
      !theftDeadboltLocks &&
      !theftLocalBurglarAlarm
    ) {
      return "None";
    }
    if (theftLocalMonitorAlarm) {
      return "Monitored Burglar Alarm";
    }
    if (theftDeadboltLocks) {
      return "Deadbolt Locks";
    }
    if (theftLocalBurglarAlarm) {
      return "Local Burglar Alarm";
    }
  };

  // else {
  //       if (theftLocalBurglarAlarm) {
  //         protectionValue = "Monitored Burglar Alarm";
  //       } else if (theftDeadboltLocks) {
  //         protectionValue = "Deadbolt Locks";
  //       } else if (theftLocalMonitorAlarm) {
  //         protectionValue = "Local Burglar Alarm";
  //       } else {
  //         protectionValue = "None";
  //       }
  //     }

  const createAdditionalInsuredData = (additionalInsured) => {
    return additionalInsured.map((insured) => ({
      n_PolicyAddInterest_PK: null,
      personType: "ADDITIONALINTEREST",
      mortgageeType: "ADDITIONALINSURED",
      loanNo: "",
      person: {
        creditScore: underWriting?.secondaryApplicantsCreditScore ?? 0,
        dob: formatDate(insured.dateOfBirth) ?? "",
        entityType: "INDIVIDUAL",
        firstName: insured.firstName ?? "",
        middleName: "",
        phoneNumber: phoneNumberFormatter(insured.phoneNumber) ?? "",
        email: insured.email ?? "",
        lastName: insured.lastName ?? "",
        isMailingDiff: "",
        isTempMailing: "",
        isInsuredTenant: "",
      },
      address: {
        sameAsMailingAddress: insured.sameAsMailingAddress,
        addressLine1:
          insured.mailingAddress.street_address ||
          formatAddress({
            street_number: location.street_number,
            street_name: location.street_name,
          }) ||
          "",
        addressLine2: "",
        city: insured.mailingAddress.city || city || "",
        stateCode: insured.mailingAddress.state || state || "",
        zip: insured.mailingAddress.zipcode || zipCode || "",
        cityId: insured.mailingAddress?.cityId ?? 0,
        stateId: insured.mailingAddress?.stateId ?? 0,
        countryId: insured.mailingAddress?.countryId ?? 1,
        country: country ?? "US",
        countyId: insured.mailingAddress?.countyId ?? 0,
        county: insured.mailingAddress?.county ?? "",
        latitude: 0,
        longitude: 0,
      },
    }));
  };

  return {
    quote_request_details: {
      primary_insured: {
        first_name: isAnOrg
          ? String(propertyDetails.buissnessName)
          : String(firstName) || "",
        last_name:
          isAnOrg && typeof propertyDetails?.customerType === "string"
            ? `(${(
                propertyDetails?.customerType?.slice(0, 3) || "(Err.)"
              ).replace(/^./, (match) => match.toUpperCase())}.)`
            : String(lastName) || "",
        email,
        phone_number: phoneNumberFormatter(phoneNumber),
        customer_type: (customerType ?? "") || "individual",
        date_of_birth: formatDate(dateOfBirth),
        current_address: current_address,

        same_as_property_address: primaryMailingAddress.sameAsPropertyAddress,
        mailing_address: {
          street_address: primaryMailingAddress?.street_address || "",
          city: primaryMailingAddress?.city || "",
          zipcode: primaryMailingAddress?.zipcode || "",
          state: primaryMailingAddress?.state || "",
          cityId: primaryMailingAddress?.cityId || 0,
          stateId: primaryMailingAddress?.stateId || 0,
          countryId: primaryMailingAddress?.countryId || 1,
          countyId: primaryMailingAddress?.countyId || 0,
          county: primaryMailingAddress?.county || "",
        },
        new_purchase: {
          is_this_new_purchase:
            underWriting?.propertyDetails?.isThisNewPurchase,
          new_purchase_address: {
            street_address:
              underWriting?.propertyDetails?.newPurchaseAddr?.street_address ||
              "",
            city: underWriting?.propertyDetails?.newPurchaseAddr?.city || "",
            zipcode:
              underWriting?.propertyDetails?.newPurchaseAddr?.zipcode || "",
            state: underWriting?.propertyDetails?.newPurchaseAddr?.state || "",
            county:
              underWriting?.propertyDetails?.newPurchaseAddr?.county || "",
            cityId: underWriting?.propertyDetails?.newPurchaseAddr?.cityId || 0,
            stateId:
              underWriting?.propertyDetails?.newPurchaseAddr?.stateId || 0,
            countryId:
              underWriting?.propertyDetails?.newPurchaseAddr?.countryId || 1,
            countyId:
              underWriting?.propertyDetails?.newPurchaseAddr?.countyId || 0,
          },
        },
        prior_policy_cancel_or_non_renew:
          underWriting?.underWritingQuestions?.coverageDeclined,
        actual_credit_score: underWriting?.creditScore,
        credit_score: additionalInsuredFlag
          ? averageCreditScore(
              underWriting?.creditScore,
              underWriting?.secondaryApplicantsCreditScore
            )
          : underWriting?.creditScore,
      },
      secondary_insured: {
        first_name: additionalInsuredFlag ? primaryInsuredInfo.firstName : "",
        last_name: additionalInsuredFlag ? primaryInsuredInfo.lastName : "",
        phone_number:
          additionalInsuredFlag &&
          primaryInsuredInfo.phoneNumber &&
          primaryInsuredInfo.phoneNumber !== "+1 "
            ? phoneNumberFormatter(primaryInsuredInfo.phoneNumber)
            : "",
        date_of_birth: additionalInsuredFlag
          ? formatDate(primaryInsuredInfo.dateOfBirth)
          : "",

        current_address: {
          street_address:
            additionalInsuredFlag && primaryInsuredInfo.sameAsMailingAddress
              ? formatAddress({
                  street_number: location.street_number,
                  street_name: location.street_name,
                })
              : primaryInsuredInfo.mailingAddress?.street_address || "",
          city:
            additionalInsuredFlag && primaryInsuredInfo.sameAsMailingAddress
              ? location?.city
              : primaryInsuredInfo.mailingAddress?.city || "",
          zipcode:
            additionalInsuredFlag && primaryInsuredInfo.sameAsMailingAddress
              ? location?.PostalCode
              : primaryInsuredInfo.mailingAddress?.zipcode || "",
          state:
            additionalInsuredFlag && primaryInsuredInfo.sameAsMailingAddress
              ? stateCode
              : primaryInsuredInfo.mailingAddress?.state || "",
        },
      },
      additional_intrest: {
        has_additional_intrest: confirmation?.additionalInterestsFlag || false,
        type: confirmation?.additionalInterests[0]?.type || "",
        firstName: confirmation?.additionalInterests[0]?.firstName || "",
        lastName: confirmation?.additionalInterests[0]?.lastName || "",
        address: confirmation?.additionalInterests[0]?.address || "",
        zipCode: confirmation?.additionalInterests[0]?.zipCode || "",
        stateCode: confirmation?.additionalInterests[0]?.stateCode || "",
        city: confirmation?.additionalInterests[0]?.city || 0,
        county: confirmation?.additionalInterests[0]?.county || "",
        cityId: confirmation?.additionalInterests[0]?.cityId || 0,
        stateId: confirmation?.additionalInterests[0]?.stateId || 0,
        countryId: confirmation?.additionalInterests[0]?.countryId || 1,
        countyId: confirmation?.additionalInterests[0]?.countyId || 0,
      },
      additional_insured:
        additionalInsured.length > 1
          ? additionalInsured.slice(1).map(createAdditionalInsured)
          : [],
      allowed_vendors: {
        credit_score: vendors?.credit_score,
        geocoding: vendors?.geocoding,
        replacement_cost: vendors?.replacement_cost,
        property_characteristics: vendors?.property_characteristics,
        cat_losses: vendors?.cat_losses,
      },
      property_details: {
        address: {
          street_address: formatAddress({
            street_number: location.street_number,
            street_name: location.street_name,
          }),
          lat: location?.latitude ? Number(location?.latitude) : 0 || 0,
          long: location?.longitude ? Number(location?.longitude) : 0 || 0,
          city: location?.city,
          zipcode: location?.PostalCode,
          state: stateCode,
          county: CountyName,
          cityId: getPKIDs(currentZipData?.data?.zipData, city)?.city_id ?? 0,
          stateId: getPKIDs(currentZipData?.data?.zipData, city)?.state_id ?? 0,
          countryId:
            country === "US"
              ? 1
              : getPKIDs(currentZipData?.data?.zipData, city)?.n_CountryId_FK &&
                typeof Number(
                  getPKIDs(currentZipData?.data?.zipData, city)?.n_CountryId_FK
                ) === "number"
              ? getPKIDs(currentZipData?.data?.zipData, city)?.n_CountryId_FK
              : 1,
          countyId:
            getPKIDs(currentZipData?.data?.zipData, city)?.county_id ?? 0,
        },
        underwriting_questions: {
          ...underWriting.underWritingQuestions,
          anyOtherBusiness:
            underWriting?.underWritingQuestions?.anyOtherBusiness,
          inPoorCondition: underWriting?.underWritingQuestions?.inPoorCondition,
          inForeClosure: underWriting?.underWritingQuestions?.inForeClosure,
          coverageDeclined:
            underWriting?.underWritingQuestions?.coverageDeclined,
          poolSecurity: underWriting?.underWritingQuestions?.poolSecurity,
          activeFloodPolicy:
            underWriting?.underWritingQuestions?.activeFloodPolicy,
          anyRecreationalVehicle:
            underWriting?.underWritingQuestions?.anyRecreationalVehicle,
          anythingSignificant:
            underWriting?.underWritingQuestions?.anythingSignificant,
          hasSolarPanels: underWriting?.underWritingQuestions?.hasSolarPanels,
          propertyExposures:
            underWriting?.underWritingQuestions?.propertyExposures,
          additionalRisks: underWriting?.underWritingQuestions?.additionalRisks,
          acres: underWriting?.underWritingQuestions?.propertyAcres,
          solarPanelLocation:
            underWriting?.underWritingQuestions?.solarPanelLocation,
          hasSwimmingPool: underWriting?.underWritingQuestions?.hasSwimmingPool,
          hasAnimals: underWriting?.underWritingQuestions?.hasAnimals,
          hasTrampoline: underWriting?.underWritingQuestions?.hasTrampoline,
          other_risks_associated:
            Array.isArray(selectedRisk) && selectedRisk.length > 0
              ? arrayToString(selectedRisk)
              : "",
          hasTrampolineAndPoolSecurity:
            underWriting?.underWritingQuestions?.hasTrampolineAndPoolSecurity ||
            false,
        },
        occupancy_properties: {
          occupancy: underWriting?.propertyDetails?.usage,
          vacant_indicator: Boolean(
            underWriting?.underWritingQuestions?.inForeClosure
          ),
          under_construction: Boolean(
            underWriting?.underWritingQuestions?.inForeClosure
          ),
          residence_use: policy?.propertyType,
        },
        construction_properties: {
          ppc: underWriting?.propertyDetails?.PPC,
          construction_type: underWriting?.propertyDetails?.constructionType,
          foundation_type: underWriting?.propertyDetails?.foundationType,
          cladding_type: underWriting?.propertyDetails?.claddingType,
          dwelling_stories:
            Number(underWriting?.propertyDetails?.dwellingStories) ?? 1,
          year_built: Number(underWriting?.propertyDetails?.yearBuilt),
          effective_year_built: underWriting?.propertyDetails?.yearBuilt,
          living_area_square_footage:
            underWriting?.propertyDetails?.squareFootage,
        },
        roof_properties: {
          roof_age: roofProperties?.roofAge,
          roof_updated: roofProperties?.roofUpdated,
          year_roof_updated: roofProperties?.roofUpdated
            ? roofProperties?.roofUpdatedYear
            : null,
          roof_partial_complete: roofProperties?.roofCompletion,
          roof_material: roofProperties?.roofMaterialUsed,
          roof_shape: roofProperties?.roofWhatShape ?? "Unknown",
          roof_modifiers: roofProperties?.roofModifier,
          roof_anchor: roofProperties?.roofAnchorUsed ?? "unknown",
          roof_deck_attachment:
            roofProperties?.roofDeckAttachments ?? "unknown",
          secondary_water_resistance:
            roofProperties?.roofSecondaryWaterResistance ?? "unknown",
        },
        protection_properties: {
          fire_smoke_alarm_type:
            fireOtherProtectionDevice || fireMonitoAlarm
              ? "central station"
              : fireLocalAlarm
              ? "local alarm"
              : "none",
          burglar_alarm_type:
            theftDeadboltLocks || theftLocalBurglarAlarm
              ? "local alarm"
              : theftLocalMonitorAlarm
              ? "central"
              : "none",
          sprinkler_system_type: hasSprinklarSystem ? sprinklerType : "none",
          smart_home: false,
          automatic_water_shutoff_type:
            fireOtherProtectionDevice || homeWaterDetection
              ? "yes - leak defense alert"
              : theftLocalMonitorAlarm
              ? "yes, leak defense"
              : "none",
          hurricane_protection_type:
            determineHurricaneProtectionType(coverages, discounts) || "unknown",
          hasSmartHomeProtection,
          homeWaterDetection,
          tingOrPlugDevices,
          hasTheftProtection,
          theftLocalBurglarAlarm,
          theftLocalMonitorAlarm,
          theftDeadboltLocks,
          hasFireProtectionDevice,
          fireMonitoAlarm,
          fireOtherProtectionDevice,
          fireLocalAlarm,
          doesQualifyForMSwindDisc,
          hasHomeInsuranceInForce,
          isApplicantLocatedNorhI10,
          fsl: discounts?.storeDiscountObj?.fsl,
          IRC: discounts?.storeDiscountObj?.IRC,
          fehBronze: discounts?.storeDiscountObj?.fehBronze,
          fehSilver: discounts?.storeDiscountObj?.fehSilver,
          fehGold: discounts?.storeDiscountObj?.fehGold,
        },
        exposure_properties: {
          farming_activity:
            underWriting?.underWritingQuestions?.anyOtherBusiness || false,
          business_flag:
            underWriting?.underWritingQuestions?.anyOtherBusiness || false,
          home_daycare_flag:
            underWriting?.underWritingQuestions?.anyOtherBusiness || false,
          trampoline_flag:
            underWriting?.underWritingQuestions?.hasTrampolineAndPoolSecurity ||
            // underWriting?.underWritingQuestions?.hasTrampoline ||
            false,
          trampoline_protection:
            underWriting?.underWritingQuestions?.hasTrampolineAndPoolSecurity ||
            false,
          animals_on_premise:
            underWriting?.underWritingQuestions?.hasAnimals ||
            underWriting?.underWritingQuestions?.animals_on_premise ||
            false,
          has_pool:
            underWriting?.underWritingQuestions?.hasTrampolineAndPoolSecurity ||
            // underWriting?.underWritingQuestions?.hasSwimmingPool ||
            false,
          pool_enclosure:
            underWriting?.underWritingQuestions?.hasTrampolineAndPoolSecurity ||
            false,
          // (underWriting?.underWritingQuestions?.hasSwimmingPool &&
          //   underWriting?.underWritingQuestions?.poolSecurity) ||
          // false,
          has_solar_panels:
            underWriting?.underWritingQuestions?.hasSolarPanels || false,
          solar_panel_location: underWriting?.underWritingQuestions
            ?.hasSolarPanels
            ? underWriting?.underWritingQuestions?.solarPanelLocation
            : null,
          property_acres: underWriting?.underWritingQuestions?.propertyAcres,
          property_acres_exposure_flag:
            underWriting?.underWritingQuestions?.propertyExposures,
          other_risks_associated:
            Array.isArray(selectedRisk) && selectedRisk.length > 0
              ? selectedRisk
              : ["None"],
        },
      },
      claim_details:
        Array.isArray(lossHistoryData) && lossHistoryData.length > 0
          ? lossHistoryData.map(createClaimDetail)
          : [],
      policy_details: {
        effective_date_timezone: "US/Central",
        general_effective_date: getPolicyEffectiveDate(),
        general_policy_term: 12,
        product: "HO-3",
        line: currentLine,
        limits: {
          coverage_a: coverages?.basicCoverages?.costToRebuildHome ?? 0,
          coverage_b: coverages?.basicCoverages?.otherStructures,
          coverage_c: coverages?.basicCoverages?.contents,
          coverage_e: parseInt(saveDDCoverages?.HOMCVGE),
          coverage_f: parseInt(saveDDCoverages?.HOMCVGF),
        },
        prior_policy: hasHomeInsuranceInForce
          ? {
              priorPolicyExpiryDate:
                formatDate(
                  discounts?.priorPolicy?.propertyInfo?.s_PriorPolicyExpDt
                ) || "",
              priorPolicyNo:
                discounts?.priorPolicy?.propertyInfo?.s_PriorPolicyNo || "",
              priorCompanyNaic:
                discounts?.priorPolicy?.propertyInfo?.s_PriorCompanyNAIC || "",
            }
          : {
              priorPolicyExpiryDate: "",
              priorPolicyNo: "",
              priorCompanyNaic: "",
            },

        deductibles: {
          aop: nonMSNonHurricaneDeductibles[coverages?.deductibles?.aop] ?? 500,
          scs: isMSState ? 500 : Number(coverages?.deductibles?.scs),
          roof:
            Number(coverages?.deductibles?.roof) === 0
              ? ""
              : Number(coverages?.deductibles?.roof),
          hurricane:
            nonMSHurricaneDeductibles[
              coverages?.deductibles?.hurricaneDeductible
            ] ?? 2,
        },
      },
      mortgageeData:
        Array.isArray(saveMortgagee) && saveMortgagee.length > 0
          ? saveMortgagee.map(createMortgageeData)
          : [],

      additionalInsuredData:
        Array.isArray(additionalInsured) &&
        additionalInsured.length > 0 &&
        additionalInsuredFlag
          ? createAdditionalInsuredData(additionalInsured)
          : [],

      ms_ho3_admitted: isMSState
        ? {
            aop_deductible: coverages?.deductibles?.aop,
            years_insured: 1,
            wind_hail_deductible: coverages?.deductibles?.hurricaneDeductible,
            wind_type: windType[trueConditionKey]
              ? windType[trueConditionKey]
              : "None",
            watercraft_liability: 0,
            medical_liability: 0,
            home_protection_theft_type: identifyTheftProtection({
              theftLocalMonitorAlarm,
              theftDeadboltLocks,
              theftLocalBurglarAlarm,
            }),
            home_protection_fire_type: identifyHomeProtection({
              tingOrPlugDevices,
              fireLocalAlarm,
              fireMonitoAlarm,
              fireOtherProtectionDevice,
            }),
            water_leak_protection: Boolean(homeWaterDetection),
            cyber_standard: false,
            roof_surface_type:
              roofProperties?.roofMaterialUsed === "3-tab Shingles"
                ? "3-Tab Shingles"
                : roofProperties?.roofMaterialUsed,
            service_line: false,
            north_of_I10: discounts?.isApplicantLocatedNorhI10,
            home_systems_protection: false, // discounts?.hasSmartHomeProtection,
            personal_jewelry_valuation: 0,
            personal_furs_valuation: 0,
            personal_music_instruments_valuation: 0,
            personal_silverware_valuation: 0,
            personal_golfer_equipment_valuation: 0,
            personal_non_commercial_valuation: 0,
            personal_stamps_baseball_card_valuation: 0,
            personal_coins_valuation: 0,
            personal_fine_arts_valuation: 0,
            outboard_horse_power: 0,
            inboard_mph: 0,
            inboard_watercraft_liability: 0,
            credit_score: additionalInsuredFlag
              ? averageCreditScore(
                  underWriting?.creditScore,
                  underWriting?.secondaryApplicantsCreditScore
                )
              : underWriting?.creditScore,
            prior_carrier: discounts?.hasHomeInsuranceInForce,

            // inside_city_limits: false,
          }
        : {},
    },

    submission_details: {
      id: uniqueID,
    },
  };
};

// endorsements: {
//   sinkhole_coverage: false,
//   sinkhole_coverage_amount: 0,
//   personal_property_replacement_cost: false,
//   special_coverage_personal_property: false,
//   increased_coverage_c: false,
//   increased_coverage_c_value: 0,
//   increased_coverage_b: false,
//   increased_coverage_b_value: 0,
//   personal_property_exclusion: false,
//   water_backup_or_overflow: false,
//   water_backup_or_overflow_value: 0,
//   personal_injury: false,
//   dwelling_replacement_cost: false,
//   dwelling_replacement_cost_value: 100,
//   building_ordinance_or_law: false,
//   building_ordinance_or_law_value: 0,
//   additional_interest_residence_premise: false,
//   additional_insured_resident_premise: false,
//   trust_endorsement: false,
//   other_structure_exclusion_schedule: false,
//   other_structure_exclusion_schedule_detail: "",
//   other_structure_exclusion_schedule_amount: 0,
//   exclusion_of_section_1_cov_b_other_structure: false,
//   structures_rented_others_residence_premise: false,
//   water_damage_exclusion: false,
//   limited_water_damage_coverage: false,
//   roof_surface_payment_schedule: false,
//   animal_liability_exclusion: false,
//   personal_and_advertising_injury_exclusion: false,
//   screen_enclosure_limit: 0,
// },

// function determineTerritory(county, city, northOfI10) {
//   let territory;
//   switch (county) {
//     case "Hancock":
//       territory = northOfI10 ? "Hancock Remainder" : "Coastal Hancock";
//       break;
//     case "Harrison":
//       territory = northOfI10 ? "Harrison Remainder" : "Coastal Harrison";
//       break;
//     case "Hinds":
//       if (city === "Jackson") {
//         territory = "City of Jackson";
//       } else {
//         territory = northOfI10 ? "Hinds" : "Hinds";
//       }
//       break;
//     case "Jackson":
//       territory = northOfI10 ? "Jackson Remainder" : "Coastal Jackson";
//       break;
//     default:
//       territory = "";
//   }
//   return territory;
// }
