import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getStarinsQuoteData } from "./apis/getStarinsQuoteData";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
export const StarDataContext = createContext();

export const StarDataProvider = ({ children }) => {
  const memoizedCalculatePercentage = useCallback((percentage, amount) => {
    if (
      typeof percentage !== "number" ||
      typeof amount !== "number" ||
      !percentage ||
      !amount
    ) {
      return 0;
    }

    if (percentage < 0 || percentage > 100) {
      return "0-100 only";
    }

    const result = (percentage / 100) * amount;
    return result.toFixed(2);
  }, []);

  const memoizedCalculateRoofAge = useCallback((year) => {
    if (
      typeof year !== "number" ||
      year <= 1800 ||
      year >= new Date().getFullYear()
    ) {
      return "";
    }
    const age = new Date().getFullYear() - year;
    return age;
  }, []);

  const [starDataObject, setStarDataObject] = useState({});
  const [uniqueID, setUniqueID] = useState(uuidv4());
  const [policy, setPolicy] = useState({
    propertyType: "Single Family",
    effectiveDate: "",
  });
  const [loading, setLoading] = useState(false);
  const [baseDD, setBaseDD] = useState([]);
  const [feesData, setFeesData] = useState({
    fees: {},
    premium: 0,
    totalFee: 0,
    finalPremium: 0,
  });

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const { signal } = controller;

    getStarinsQuoteData(signal, "Ho3")
      .then((data) => {
        if (data) {
          setBaseDD(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching quote base data:", error);
        toast.error("Failed to fetch quote base data");
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, []);

  const [underWriting, setUnderWriting] = useState({
    propertyDetails: {
      PPC: "",
      isThisNewPurchase: false,
      newPurchaseAddressSameAsMailing: true,
      firstName: null,
      lastName: null,
      phoneNumber: "",
      email: "",
      squareFootage: null,
      yearBuilt: null,
      usage: null,
      constructionType: null,
      numberOfFloors: null,
      vacantIndicator: false,
      underConstruction: false,
      hasTrampolineAndPoolSecurity: false,
      foundationType: null,
      claddingType: null,
      dwellingStories: null,
      numberOfBuildings: 1,
      numberOfFamilies: 1,
      customerType: "individual", //individual, partnership, organization, corporation, llc, llp, trust && individual is default
      dateOfBirth: "01/01/1970",
      buissnessName: "",
      newPurchaseAddr: {
        street_address: "",
        city: "",
        state: "",
        zipcode: "",
        county: "",
        cityId: 0,
        stateId: 0,
        countryId: 1,
        countyId: 0,
      },
    },
    lossHistoryQuestion: false,
    underWritingQuestions: {
      anyOtherBusiness: false,
      inPoorCondition: false,
      inForeClosure: false,
      coverageDeclined: false,
      poolSecurity: false,
      activeFloodPolicy: false,
      anyRecreationalVehicle: false,
      anythingSignificant: false,
      propertyAcres: null,
      propertyExposures: false,
      hasSolarPanels: false,
      solarPanelLocation: "On the house",
      additionalRisks: false,
      hasSwimmingPool: false,
      hasAnimals: false,
      hasTrampoline: false,
      hasTrampolineProtection: false,
    },
    creditScore: 750,
    secondaryApplicantsCreditScore: 650,
    checkboxes: {
      readFcraStatement: false,
    },
  });
  const [selectedRisk, setSelectedRisk] = useState([]);
  const [coveragesTriggered, setCoveragesTriggered] = useState(false);
  const [acknowledgementModal, setIsAcknowledgementModal] = useState(false);
  const [discounts, setDiscounts] = useState({
    hasSmartHomeProtection: false,
    homeWaterDetection: false,
    tingOrPlugDevices: false,
    hasTheftProtection: false,
    theftLocalBurglarAlarm: false,
    theftLocalMonitorAlarm: false,
    theftDeadboltLocks: false,
    hasFireProtectionDevice: false,
    fireMonitoAlarm: false,
    fireOtherProtectionDevice: false,
    fireLocalAlarm: false,
    doesQualifyForMSwindDisc: false,
    hasHomeInsuranceInForce: false,
    isApplicantLocatedNorhI10: false,
    hasSprinklarSystem: false,
    sprinklerType: "none",
    storeDiscountObj: {
      fsl: false,
      IRC: false,
      fehBronze: false,
      fehSilver: false,
      fehGold: false,
    },
    priorPolicy: {
      propertyInfo: {
        s_PriorPolicyExpDt: "",
        s_PriorPolicyNo: "",
        s_PriorCompanyNAIC: "",
      },
    },
  });

  useEffect(() => {
    Object.keys(discounts?.storeDiscountObj).length > 0 &&
      Object.values(discounts?.storeDiscountObj).includes(true) &&
      setIsAcknowledgementModal(true);
  }, [discounts?.storeDiscountObj]);

  const [coverages, setCoverages] = useState({
    basicCoverages: {
      costToRebuildHome: "", //coverage a
      otherStructures: 0, //coverage b
      contents: 0, //coverage c
      personalLiability: 300000, //coverage e
      medicalPaymentsToOthers: 1000, //coverage f
    },
    deductibles: {
      aop: "DED02P",
      scs: "500",
      hurricaneDeductible: "HURRA02P",
      roof: "",
      isCountyRisky: false,
    },
  });

  const [hasMortagee, setHasMortagee] = useState(false);
  const [saveMortgagee, setSaveMortgagee] = useState([]);
  const [location, setLocation] = useState({});
  const [currentZipData, setCurrentZipData] = useState({});
  const [autoGenData, setAutoGenData] = useState({});
  const [confirmation, setConfirmation] = useState({
    mortgagees: {
      onHome: {
        available: true,
        bankName: "",
        loanNumber: "",
      },
      mailingAddress: {
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
        noInformationAvailable: false,
      },
    },
    additionalInterestsFlag: false,
    additionalInterests: [
      {
        type: "",
        firstName: "",
        lastName: "",
        address: "",
        zipCode: null,
        stateCode: null,
        city: "",
        county: "",
        cityId: 0,
        stateId: 0,
        countryId: 1,
        countyId: 0,
      },
    ],
    additionalInsuredFlag: false,
    additionalInsured: [
      {
        sameAsMailingAddress: true,
        firstName: "",
        lastName: "",
        mailingAddress: {
          street_address: "",
          city: "",
          zipcode: "",
          state: "",
          county: "",
          cityId: 0,
          stateId: 0,
          countryId: 1,
          countyId: 0,
        },
        phoneNumber: "",
        email: "",
        dateOfBirth: "01/01/1975",
      },
    ],
    checkboxes: {
      readSubscriberAgreement: false,
      readTermsAndConditions: false,
      readFraudStatement: false,
    },
  });
  const [agreements, setAgreements] = useState({
    acknowledge1: false,
    acknowledge2: false,
    acknowledge3: false,
    acknowledge4: false,
    acknowledge5: false,
    acknowledge6: false,
    acknowledge7: false,
    acknowledge8: false,
  });
  const [validationError, setValidationError] = useState({
    propertyType: true,
    effectiveDate: false,
    underWritingPage: false,
    lossHistory: false,
    coverages: false,
    confirmation: false,
    subscriberAgreement: false,
    termsAndConditions: false,
    fraudStatement: false,
    additionalInterests: false,
    additionalInsured: false,
    agreements: false,
    discounts: false,
  });

  const [roofProperties, setRoofProperties] = useState({
    roofAge: null,
    roofUpdated: false,
    roofUpdatedYear: null,
    roofCompletion: "Complete",
    roofMaterialUsed: "",
    roofWhatShape: "Unknown",
    roofAnchorUsed: "unknown",
    roofModifier: false,
    roofDeckAttachments: "unknown",
    roofSecondaryWaterResistance: "unknown",
  });
  const [otherStructuresPercentage, setOtherStructuresPercentage] =
    useState(10);
  const [contentsPercentage, setContentsPercentage] = useState(50);
  const [boundData, setBoundData] = useState({});
  const [lossHistoryData, setLossHistoryData] = useState([]);
  const [cardSelected, setCardSelected] = useState(0);
  const [stateCodeVal, setStateCodeVal] = useState("");
  const [editQuoteRes, setEditQuoteRes] = useState({});
  const [errorMessages, setErrorMessages] = useState({});

  //ONLY DISPLAY PURPOSES
  const [primaryMailingAddress, setPrimaryMailingAddress] = useState({
    sameAsPropertyAddress: true,
    street_address: "",
    city: "",
    zipcode: "",
    state: "",
    county: "",
    cityId: 0,
    stateId: 0,
    countryId: 1,
    countyId: 0,
  });
  const [saveDDCoverages, setSaveDDCoverages] = useState({
    HOMCVGF: 1000,
    HOMCVGE: "100000.00", //need minimum default
  });

  useEffect(() => {
    setCoverages(() => ({
      ...coverages,
      basicCoverages: {
        ...coverages.basicCoverages,
        otherStructures: parseFloat(
          memoizedCalculatePercentage(
            otherStructuresPercentage,
            coverages.basicCoverages.costToRebuildHome
          )
        ),
        contents: parseFloat(
          memoizedCalculatePercentage(
            contentsPercentage,
            coverages.basicCoverages.costToRebuildHome
          )
        ),
      },
    }));

    // eslint-disable-next-line
  }, [
    otherStructuresPercentage,
    contentsPercentage,
    coverages.basicCoverages.costToRebuildHome,
  ]);

  const [vendors, setVendors] = useState({
    credit_score: false,
    geocoding: false,
    replacement_cost: false,
    property_characteristics: false,
    cat_losses: false,
  });

  useEffect(() => {
    if (
      underWriting &&
      underWriting.propertyDetails &&
      underWriting.propertyDetails.yearBuilt
    ) {
      const yearBuilt = Number(underWriting.propertyDetails.yearBuilt);
      const roofAge = memoizedCalculateRoofAge(yearBuilt);
      if (roofAge) {
        setRoofProperties((prevProperties) => ({
          ...prevProperties,
          roofAge,
        }));
      }
    }
    // eslint-disable-next-line
  }, [underWriting.propertyDetails.yearBuilt]);

  const [mortgagees, setMortgagees] = useState({
    type: "Primary",
    bankName: "",
    loanNumber: "",
    address: "",
    zipCode: "",
    stateCode: "",
    city: "",
    county: "",
    cityPk: "",
    statePk: "",
    countyPk: "",
    countryPk: "",
  });

  const value = {
    starDataObject,
    setStarDataObject,
    location,
    setLocation,
    discounts,
    setDiscounts,
    policy,
    setPolicy,
    underWriting,
    setUnderWriting,
    coverages,
    setCoverages,
    validationError,
    setValidationError,
    confirmation,
    setConfirmation,
    agreements,
    setAgreements,
    autoGenData,
    setAutoGenData,
    loading,
    setLoading,
    baseDD,
    selectedRisk,
    setSelectedRisk,
    uniqueID,
    setUniqueID,
    hasMortagee,
    setHasMortagee,
    saveMortgagee,
    setSaveMortgagee,
    roofProperties,
    setRoofProperties,
    boundData,
    setBoundData,
    lossHistoryData,
    setLossHistoryData,
    cardSelected,
    setCardSelected,
    otherStructuresPercentage,
    setOtherStructuresPercentage,
    contentsPercentage,
    setContentsPercentage,
    saveDDCoverages,
    setSaveDDCoverages,
    primaryMailingAddress,
    setPrimaryMailingAddress,
    stateCodeVal,
    setStateCodeVal,
    currentZipData,
    setCurrentZipData,
    vendors,
    setVendors,
    editQuoteRes,
    setEditQuoteRes,
    mortgagees,
    setMortgagees,
    acknowledgementModal,
    setIsAcknowledgementModal,
    feesData,
    setFeesData,
    errorMessages,
    setErrorMessages,
    coveragesTriggered,
    setCoveragesTriggered,
  };
  return (
    <StarDataContext.Provider value={value}>
      {children}
    </StarDataContext.Provider>
  );
};

export const useStarDataContext = () => {
  try {
    return useContext(StarDataContext);
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
